import { Typography, Icon } from '@mui/material';
import './footer.css';

export function Footer() {
	return (
		<footer>
			<Typography variant='subtitle1'>
				Made with{' '}
				<Icon className="heart" fontSize='medium' sx={{ marginLeft: '0.4rem' }}>
					favorite
				</Icon>
			</Typography>
		</footer>
	);
}
