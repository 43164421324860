import {
	Card,
	CardContent,
	CardHeader,
	Typography,
	Link,
	Icon,
	CardMedia,
} from '@mui/material';
import './projects.css';

export function Projects() {
	return (
		<section className='projects'>
			<div className='cards'>
				<ProjectCard />
				<ProjectCard2 />
				<ProjectCard3 />
			</div>
		</section>
	);
}

function ProjectCard() {
	return (
		<Card>
			<CardHeader
				title='Dingo'
				subheader='2nd Place at Square POS-sible'
				action={
					<Link
						target='_blank'
						color='#000000'
						href='https://devpost.com/software/square-product-recommendation'
					>
						<Icon className='link-icon'>link</Icon>
					</Link>
				}
			/>
			<CardMedia
				component='img'
				height='175'
				image='https://res.cloudinary.com/devpost/image/fetch/s--Gd683vxA--/c_limit,f_auto,fl_lossy,q_auto:eco,w_900/https://i.imgur.com/Qag3mx2.png'
				alt='Dingo Image'
			/>
			<CardContent>
				<Typography variant='body1' color='text.secondary'>
					Dingo enables Square merchants to provide personalized item recommendations and/or discounts to their customers. When a customer makes a purchase, Dingo utilizes all customer purchase history to generate a smart recommendation. The customer will then receive their personalized coupon in a text.
				</Typography>
			</CardContent>
		</Card>
	);
}

function ProjectCard2() {
	return (
		<Card>
			<CardHeader
				title='Traffiq'
				subheader='3rd Place at AWS Graviton'
				action={
					<Link
						target='_blank'
						color='#000000'
						href='https://devpost.com/software/traffiq'
					>
						<Icon className='link-icon'>link</Icon>
					</Link>
				}
			/>
			<CardMedia
				component='img'
				height='175'
				image='https://res.cloudinary.com/devpost/image/fetch/s--nlgrYuU8--/c_limit,f_auto,fl_lossy,q_auto:eco,w_900/https://i.imgur.com/JWpYP76.png'
				alt='Traffiq Image'
				sx={{
					objectFit: 'none',
				}}
			/>
			<CardContent>
				<Typography variant='body1' color='text.secondary'>
					Traffiq is a queue-based solution to protect web applications from spikes in traffic. Website owners can configure a target latency in their Admin dashboard. When a user visits their site, Traffiq will use the server's latency to determine whether to let the user in or redirect them to a queue.
				</Typography>
			</CardContent>
		</Card>
	);
}

function ProjectCard3() {
	return (
		<Card>
			<CardHeader
				title='Joyride'
				subheader='Best Design at Citrus Hack 2019'
				action={
					<Link
						target='_blank'
						color='#000000'
						href='https://devpost.com/software/carpuul'
					>
						<Icon className='link-icon'>link</Icon>
					</Link>
				}
			/>
			<CardMedia
				component='img'
				height='175'
				image='https://challengepost-s3-challengepost.netdna-ssl.com/photos/production/software_photos/000/806/277/datas/original.png'
				alt='Joyride Image'
				sx={{}}
			/>
			<CardContent>
				<Typography variant='body1' color='text.secondary'>
					Joyride helps organize and optimize carpooling. The driver starts by inputting each passenger's phone number and address. Then, Joyride will generate the optimal driving route, and send each passenger the driver's ETA. Upon each arrival, an optional arrival notification can be sent.
				</Typography>
			</CardContent>
		</Card>
	);
}
