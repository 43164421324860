import { Typography, Link, Icon } from '@mui/material';
import './hero.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const DEFAULT_LINK_COLOR = '#1d1c1c';

export function Hero() {
	return (
		<div className='hero'>
			<header>
				<Typography variant='h2' sx={{ fontWeight: 400 }} align='center'>Joshua Sun</Typography>
				<Typography variant='body1' gutterBottom={true}>
					Software Engineer
				</Typography>

				<div className='links'>
					<Link
						target='_blank'
						color={DEFAULT_LINK_COLOR}
						href='https://www.linkedin.com/in/joshua-sun/'
					>
						<LinkedInIcon fontSize="large" />
					</Link>

					<Link
						target='_blank'
						color={DEFAULT_LINK_COLOR}
						href='https://drive.google.com/drive/folders/1aiupZUfL7nObTbifDN4fL3972gaN-NOR'
					>
						<Icon fontSize="large">description</Icon>
					</Link>
				</div>
			</header>
		</div>
	);
}
