import { Paper, Typography } from '@mui/material';
import './about.css';

export function About() {
	return (
		<div className='about-me'>
			<Paper
				className='paper'
				sx={{
					padding: '2rem',
					margin: 'auto',
					marginBottom: '2rem',
				}}
			>
				<Typography variant='body1'>
					I am a Full Stack Developer that loves working on impactful
					projects. I push for high standards and long-term value.
				</Typography>
			</Paper>
			<Paper
				className='paper'
				sx={{
					padding: '2rem',
					margin: 'auto',
					marginBottom: '2rem',
				}}
			>
				<Typography variant='body1'>
					I am very curious, and am always learning new things. I
					enjoy learning from others, and sharing information with
					others.
				</Typography>
			</Paper>
			<Paper
				className='paper'
				sx={{
					padding: '2rem',
					margin: 'auto',
				}}
			>
				<Typography variant='body1'>
					I strive to be open-minded and humble. I welcome
					constructive feedback.
				</Typography>
			</Paper>
		</div>
	);
}
