import { useRef } from 'react';
import './App.css';
import { Hero } from './components/hero';
import { Navbar } from './components/navbar';
import { Section } from './components/section';
import { Projects } from './components/projects';
import { About } from './components/about';
import { Footer } from './components/footer';

function App() {
	const topRef = useRef<HTMLElement | null>(null);
	const firstRef = useRef<HTMLElement | null>(null);
	const secondRef = useRef<HTMLElement | null>(null);
	const sections = [
		{
			ref: firstRef,
			label: 'About',
		},
		{
			ref: secondRef,
			label: 'Projects',
		},
	];

	return (
		<>
			<Navbar sections={sections} topRef={topRef} />
			<main>
				<Section ref={topRef}>
					<Hero />
				</Section>
				<Section ref={firstRef} header='About Me'>
					<About />
				</Section>
				<Section ref={secondRef} header='Featured Projects'>
					<Projects />
				</Section>
			</main>
			<Footer />
		</>
	);
}

export default App;
