import { forwardRef, ReactNode } from 'react';
import './section.css';
import { Typography } from '@mui/material';

interface SectionProps {
	children?: ReactNode;
	header?: string;
}

export const Section = forwardRef<HTMLElement, SectionProps>((props, ref) => {
	const { children, header } = props;

	return (
		<section ref={ref} className='section'>
			{header && (
				<Typography
					variant='h3'
					align='center'
					className='header'
				>
					{header}
				</Typography>
			)}
			{children}
		</section>
	);
});
