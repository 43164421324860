import { Link } from '@mui/material';
import { useState } from 'react';
import { MutableRefObject, useEffect } from 'react';
import './navbar.css';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

interface NavbarProps {
	topRef?: MutableRefObject<HTMLElement | null>;
	sections?: Array<{
		ref: MutableRefObject<HTMLElement | null>;
		label: string;
	}>;
}

export function Navbar(props: NavbarProps) {
	const { sections, topRef } = props;
	const [hasScrolled, setHasScrolled] = useState(false);

	const checkScroll = () => {
		setHasScrolled(window.scrollY !== 0);
	};

	useEffect(() => {
		// Check initial scroll position
		checkScroll();

		document.addEventListener('scroll', checkScroll);
		return () => document.removeEventListener('scroll', checkScroll);
	}, []);

	const contrastClass = hasScrolled ? 'contrasted' : '';

	return (
		<nav className={`navbar ${contrastClass}`}>
			<Link
				color='#000000'
				className={`link up-arrow ${hasScrolled ? '' : 'return'}`}
				component='button'
				onClick={() => {
					topRef?.current?.scrollIntoView({
						behavior: 'smooth',
					});
				}}
			>
				<ArrowCircleUpIcon fontSize="large" />
			</Link>
			{sections?.map((section) => {
				const { label, ref } = section;
				return (
					<Link
						key={label}
						color='#000000'
						className='link'
						variant='h5'
						component='button'
						onClick={() =>
							ref.current?.scrollIntoView({
								behavior: 'smooth',
							})
						}
					>
						{label}
					</Link>
				);
			})}
		</nav>
	);
}
